:root {
  --percentageRotate: 90;
}

.chart {
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
}

.chart *,
.chart::before {
  box-sizing: border-box;
}

.chart {
  position: relative;
  width: 186px;
  height: 93px;
  overflow: hidden;
}

.chart::before,
.chart::after {
  position: absolute;
}

.chart::before {
  content: "";
  width: inherit;
  height: inherit;
  border: 16px solid #f1f5f9;
  border-bottom: none;
  border-top-left-radius: 93px;
  border-top-right-radius: 93px;
}

.chart div {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: inherit;
  border: 16px solid #f1f5f9;
  border-top: none;
  border-bottom-left-radius: 93px;
  border-bottom-right-radius: 93px;
  transform-origin: 50% 0;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
  animation-timing-function: linear;
  background: white;
}

.chart div:nth-child(1) {
  z-index: 4;
  border-color: #aa7dce;
  transition: all 2s;
  transform: rotate(calc(var(--percentageRotate) * 1deg));
}

.chart span {
  position: absolute;
  font-size: 18px;
  backface-visibility: hidden;
  color: white;
  animation: fade-in 0.4s linear forwards;
}

.chart .candidate-one-percentage {
  top: 10px;
  left: -26px;
  transform: rotate(-90deg);
}

.chart .candidate-two-percentage {
  top: -26px;
  left: -26px;
  transform: rotate(-90deg);
}

@keyframes rotate-one {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(calc(--percentageRotate * 1deg));
  }
}
