@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media (min-resolution: 2ddpx) {
  svg {
    shape-rendering: optimizeSpeed;
  }
}

.deletePrice {
  color: #475569;
  font-family: PingFangSC-light, sans-serif;
  text-decoration: line-through;
}

.跑馬燈 {
  position: relative;
  left: -110px;
  font-size: 30px;
  width: 1480px;
  overflow: hidden;
  color: #db5461;
}

.跑馬燈字 {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 15s linear infinite;
}

.跑馬燈字2 {
  position: relative;
  left: 1480px;
  display: inline-block;
  white-space: nowrap;
  animation: marquee2 15s linear infinite;
  animation-delay: 7.5s;
}

@keyframes marquee {
  0% {
    transform: translateX(1480px);
  }

  100% {
    transform: translateX(-1480px);
  }
}

@keyframes marquee2 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-2960px);
  }
}

svg {
  shape-rendering: optimizeSpeed;
}

html {
  font-size: 16px;
  color: #0f172a;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  overflow-x: hidden;
}

body {
  margin-top: 11vh;
  line-height: 28px;
  font-family: PingFangSC-Light, sans-serif;
  font-size: 16px;
}

h1 {
  font-family: PingFangSC-Semibold, sans-serif;
  font-size: 48px;
  line-height: 67px;
  letter-spacing: -0.012em;
}

h2 {
  font-family: PingFangSC-Semibold, sans-serif;
  font-size: 30px;
  line-height: 42px;
  letter-spacing: -0.012em;
}

h3 {
  font-family: PingFangSC-Medium, sans-serif;
  font-size: 24px;
  line-height: 33.6px;
  letter-spacing: -1.2%;
  margin-top: 20px;
}

h4 {
  font-family: PingFangSC-Semibold, sans-serif;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -1.2%;
  color: #475569;
}

h5 {
  font-size: 16px;
}

button {
  min-width: 100px;
  width: auto;
  height: 40px;
  text-align: center;
  padding: 0 20px;
  font-size: 16px;
  border: none;
  border-radius: 10em/10em;
  background-color: #0f172a;
  color: #ffffff;
  cursor: pointer;
}

button:hover {
  background-color: #6b92f7;
  color: #ffffff;
}

button:focus {
  background-color: #6089f1;
  color: #ffffff;
}

button:disabled {
  background-color: #f1f5f9;
  color: #94a3b8;
}

.secondary_btn {
  border: 1px solid #cbd5e1;
  background-color: #ffffff;
  color: #475569;
}

.secondary_btn:hover {
  background-color: #cbd5e1;
  color: #e9effe;
}

.secondary_btn:active {
  border: none;
  background-color: #e2e8f0;
}

select {
  border: solid 1px #cbd5e1;
  border-radius: 4px;
  color: #2d3648;
  font-family: PingFangSC-Light, sans-serif;
  font-size: 16px;
  margin: 10px 0 4px 20px;
  height: 40px;
  padding: 0px 5px;
  width: auto;
}

input,
textarea {
  min-width: 450px;
  width: 50%;
  border: solid 1px #cbd5e1;
  border-radius: 4px;
  color: #2d3648;
  font-family: PingFangSC-Light, sans-serif;
  font-size: 16px;
  margin: 5px 0 4px 0;
}

input {
  padding: 0px 5px;
  height: 40px;
}

textarea {
  padding: 20px 15px;
  line-height: 28px;
}

.radio {
  width: 17px;
  min-width: 17px;
  height: 13px;
  margin: 20px;
  margin-right: 10px;
  margin-left: 0px;
}

.material-symbols-rounded,
.material-symbols-outlined {
  position: relative;
  font-size: 17px;
  top: 3px;
}

a,
a:visited {
  color: #475569;
  text-decoration: none;
}

li {
  list-style: none;
}

p {
  margin: 15px 0;
}

label {
  text-align: left;
  margin-top: 1rem;
}

.loginPage {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 700px;
  z-index: 1;
}

.login,
.register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  width: 500px;
}

.login form,
.register form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.login form label,
.register form label,
.login input,
.register input {
  width: 100%;
}

.instructions {
  width: 100%;
  font-size: 0.75rem;
  color: red;
  position: relative;
  bottom: -10px;
  text-align: left;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.hover:hover {
  cursor: pointer;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-top: 1rem;
}

.line {
  display: inline-block;
}

.Nav {
  font-family: PingFangSC-Semibold, sans-serif;
  background-color: #fff;
  color: #475569;
  display: flex;
  position: fixed;
  width: 100vw;
  height: 70px;
  border-bottom: solid 1px #e2e8f0;
  padding: 5px 24px;
  top: 0px;
  z-index: 10;
  align-items: center;
}

.NavLinks {
  font-family: PingFangSC-Semibold, sans-serif;
  color: #475569;
  position: fixed;
  top: -5px;
  left: 14%;
  z-index: 10;
}

.Nav .logo {
  margin-top: 7px;
  margin-left: 16px;
}

.navLinks {
  position: absolute;
  display: flex;
  align-items: center;
  right: 3%;
}

.navLink {
  padding: 6px 15px;
  margin: 0 12px;
  border-radius: 10em/10em;
  float: right;
}

.navBtn {
  padding: 6px 0px;
  margin: 0px 8px;
  border-radius: 10em/10em;
  float: right;
}

/*DropDown_start*/
.dropdown-menu {
  font-family: PingFangSC-Light, sans-serif;
  width: 180px;
  position: absolute;
  top: 7vh;
  right: 38px;
  text-align: start;
  border: 1px solid #f1f5f9;
  border-radius: 6px;
  background: #ffffff;
  cursor: pointer;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.05));
  font-size: 14px;
}

.dropdown-menu a:visited {
  color: #475569;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link,
.logout {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #475569;
  padding: 5px;
}

.dropdown-menu .hover {
  border-radius: 6px;
  width: 100%;
  height: 37px;
  padding: 5px 8px;
}

.dropdown-menu .hover:hover {
  background-color: #f1f5f9;
}

.dropdown-menu .user {
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #475569;
  padding: 7px;
  padding-left: 40px;
  border-bottom: 1px solid #f1f5f9;
}

.dropdown-menu .logout {
  border-top: 1px solid #f1f5f9;
}

/*
@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
*/
/*DropDown_end*/

/*AddFile_start*/
.addFile,
.myWritings {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.account,
.addFile,
.myWritings {
  position: absolute;
  width: calc(100vw - 240px);
  right: 0;
  justify-content: left;
  align-items: left;
  padding-left: 40px;
  padding-right: 40px;
  text-align: left;
}

.asistant {
  position: relative;
  width: calc(100vw - 240px);
  overflow: hidden;
  justify-content: left;
  align-items: left;
  padding-left: 40px;
  padding-right: 40px;
  text-align: left;
  left: 240px;
}

.asistant input {
  width: 24.5vw;
  min-width: 24.5vw;
}

.asistant textarea {
  width: 52vw;
  padding: 10px;
}

.screen {
  display: flex;
  gap: 55px;
  transition: transform 1.5s;
  transform: translateX(0);
}

.screenResult {
  display: flex;
  gap: 55px;
  transition: transform 1.5s;
  transform: translateX(-56vw);
}

.myWritings div {
  justify-content: left;
  align-items: stretch;
}

.promo {
  width: 100%;
  height: 73px;
  margin-bottom: 20px;
}

.addDocTypes {
  width: 100%;
  height: 391px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 24px;
}

.addDocType {
  position: relative;
  background: #f8fafc;
  border-radius: 2px;
  width: 32%;
  min-width: 250px;
  height: 391px;
  word-wrap: break-word;
  padding: 40px 32px 40px 32px;
  text-align: left;
}

.addDocType button {
  width: auto;
}

.myWritings button {
  float: right;
}

.myWritings .docDivs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.myWritings .docDiv {
  position: relative;
  width: 22.5%;
  min-width: 232px;
  height: 220px;
  margin-top: 12px;
  background: #f8fafc;
  border-radius: 2px;
  padding: 24px;
}

.myWritings .docDiv:hover {
  background: #f1f5f9;
  cursor: pointer;
}

.quotaDiv {
  position: relative;
  width: 22.5%;
  min-width: 232px;
  height: 220px;
  margin-top: 12px;
  background: #f8fafc;
  border-radius: 2px;
  padding: 24px;
}

.myWritings .docsSec {
  margin-top: 10px;
}

.myWritings .docTitle {
  font-family: PingFangSC-Semibold, sans-serif;
  margin-top: 12px;
  margin-bottom: 25px;
}

.myWritings .date {
  color: #475569;
  margin-top: 10px;
  font-size: 14px;
}

.myWritings .goToDoc {
  height: 100%;
}

.myWritings .optimization {
  position: absolute;
  bottom: 24px;
  left: 24px;
}

.myWritings .deleteDoc {
  position: absolute;
  bottom: 1vh;
  right: 1vw;
}

.DownloadWriting:hover {
  cursor: pointer;
}

/*AddFile_end*/

/*sidebar_start*/
.sidebar {
  position: fixed;
  height: calc(100vh - 70px);
  width: 240px;
  top: 70px;
  left: 0px;
  background-color: #f8fafc;
  font-family: PingFangSC-Light, sans-serif;
  font-size: 14px;
  color: #475569;
  padding-top: 24px;
  text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
  z-index: 9;
}

.sidebarDocs {
  margin-top: 8px;
  margin-bottom: 8px;
}

.docList,
.listTitle {
  padding: 8px 16px;
  border-left: solid 3px #f8fafc;
  color: #475569;
}

.listTitle {
  font-weight: 600;
}

.docList {
  cursor: pointer;
}

.activeList {
  padding: 8px 16px;
  border-left: solid 3px #6b92f7;
  color: #6b92f7;
}

.docList:hover,
.activeList:hover {
  background-color: #f1f5f9;
}

.docList:active,
.activeList:active {
  background-color: #e2e8f0;
}

.docList:focus,
.activeList:focus {
  border: solid 2px #6b92f7;
}

.sidebar_quota {
  color: #475569;
  width: 100%;
  height: 80px;
  padding: 24px 19px;
  position: absolute;
  border-top: solid 1px #e2e8f0;
  background-color: #f8fafc;
  bottom: 0;
}

/*sidebar_end*/

/*formTabs_start*/
.TabSect {
  position: fixed;
  top: 70px;
  right: 0vw;
  text-align: left;
  min-width: calc(100vw - 240px);
  min-height: auto;
  padding-top: 24px;
  padding-left: 45px;
  padding-right: 45px;
  padding-bottom: 2vh;
  background-color: #fff;
  z-index: 8;
}

.TabSect input {
  height: 48px;
  font-size: 23px;
  font-family: PingFangSC-Medium, sans-serif;
  letter-spacing: -0.012em;
  color: #2d3648;
  margin-top: 2vh;
  margin-bottom: 3.5vh;
  border: solid lightgray;
  opacity: 0.7;
}

.TabSect ::placeholder {
  color: lightgrey;
  opacity: 0.8;
  /* Firefox */
}

.tabs {
  display: flex;
  width: fit-content;
  font-size: 14px;
  margin-left: 15px;
}

.tab {
  width: fit-content;
  text-align: center;
  color: #94a3b8;
}

.activeTab {
  font-weight: bold;
  width: fit-content;
  text-align: center;
  color: #6b92f7;
}

.checked {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #6b92f7;
}

.checkedLine {
  width: 88px;
  height: 3px;
  background-color: #6b92f7;
}

.whiteLine {
  width: 88px;
  height: 3px;
  background-color: white;
}

.unchecked {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #6b92f7;
}

.uncheckedLine {
  width: 88px;
  height: 3px;
  background-color: #cbd5e1;
}

.noShow {
  display: none;
}

.showTrprt {
  opacity: 0.5;
}

/*formTabs_end*/

/*FormStyle_start*/
.formStyle {
  position: absolute;
  width: calc(100vw - 240px);
  right: 0;
  top: 280px;
  padding-left: 45px;
  padding-right: 45px;
  padding-bottom: 6vh;
  font-size: 16px;
}

.formStyle form {
  text-align: left;
}

.formStyle form > div {
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
}

.subtitle {
  margin-top: 5px;
}

.help {
  font-size: 14px;
  color: #475569;
  line-height: 20px;
}

.suggest {
  line-height: 23px;
  font-size: 14px;
  font-weight: 600;
  color: #475569;
}

.formStyle ul {
  list-style-position: outside;
  position: relative;
  left: 18px;
}

.formStyle li {
  line-height: 20px;
  margin: 0 0 10px 0;
  padding: 0;
}

.rows {
  display: flex;
  gap: 45px;
}

.row {
  flex: none;
  width: 47%;
}

.row textarea {
  width: 100%;
  min-width: 350px;
  height: 580px;
  padding: 5px;
}

.sectitle {
  font-size: 20px;
  padding-bottom: 1vh;
}

/*formStyle_end*/
.TabsResult {
  text-align: left;
  width: calc(100vw - 240px);
  min-height: auto;
  margin-bottom: 15px;
}

.title {
  font-size: 24px;
  font-family: PingFangSC-Semibold, sans-serif;
  letter-spacing: -0.012em;
  margin-top: 20px;
  margin-bottom: 35px;
}

.resultTabs {
  flex: none;
  width: 50vw;
  margin-bottom: 10px;
  font-family: PingFangSC-Semibold, sans-serif;
}

.activeResultTab {
  display: inline-block;
  width: fit-content;
  color: #475569;
  margin-right: 2vw;
  padding-bottom: 4px;
  border-style: none none solid none;
}

.resultTab {
  color: #94a3b8;
  display: inline-block;
  width: fit-content;
  margin-right: 2vw;
  padding-bottom: 4px;
}

.result {
  float: right;
  text-align: left;
  width: calc(100% - 240px);
  min-height: auto;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 2vh;
}

.resultRow {
  display: flex;
}

.editArea {
  flex: none;
  width: 51vw;
}

.editArea textarea {
  width: 100%;
  margin: 5px 0;
}

strong {
  color: #db5461;
  font-weight: 400;
  text-decoration-line: underline;
}

.float_btn {
  position: fixed;
  bottom: 8vh;
  right: 4vw;
  z-index: 8;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1),
    0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.resultRight {
  position: fixed;
  right: 40px;
  top: 17vh;
  width: 27vw;
}

.tips {
  position: relative;
  top: -40px;
  font-size: 16px;
  flex: none;
  margin-left: 40px;
}

.tipB {
  width: 10px;
  height: 10px;
  border-radius: 5rem;
  background-color: #94a3b8;
  cursor: pointer;
}

.tipCurrent {
  width: 10px;
  height: 10px;
  border-radius: 5rem;
  background-color: black;
  cursor: pointer;
}

.tipTitle {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: left;
  font-family: PingFangSC-Semibold, sans-serif;
}

.tipHead {
  flex: auto;
  padding-top: 1vh;
}

.starRating {
  flex: none;
  width: auto;
}

.emptyStar,
.halfStar,
.fullStar {
  width: 15px;
  margin-left: 4px;
}

.tip {
  color: #475569;
  font-size: 16px;
  margin-bottom: 20px;
}

.extra {
  position: relative;

  margin-top: 16px;
  background-color: rgba(170, 125, 206, 0.12);
  border-radius: 2px;
  padding: 2vh 16px 2vh 16px;
}

.colorText {
  color: #6b92f7;
  margin: 15px 0;
}

.redText {
  color: #db5461;
}

.grayText {
  color: #64748b;
}

.biggerText {
  font-size: 36px;
  font-family: PingFangSC-Semibold, sans-serif;
  color: #db5461;
}

.spin {
  animation-name: spin;
  animation-duration: 600ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/*completion*/
.completion {
  position: absolute;
  width: calc(100vw - 240px);
  right: 0;
  top: 70px;
  background-color: #ffffff;
  z-index: 10;
  height: calc(100vh - 70px);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.account {
  height: 92vh;
}

.account h2 {
  margin-bottom: 8vh;
}

.account > .rows {
  margin-bottom: 4vh;
}

.table {
  border-collapse: collapse;
  text-align: left;
}

.table thead {
  color: #6e6893;
  background-color: #f4f2ff;
}

.table td,
.table th {
  min-width: 200px;
  height: 50px;
  padding: 15px;
}

.table td {
  border-bottom: 1px solid #cbd5e1;
}

.radioButtuns label {
  margin-right: 3vw;
}

.shimmer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: loading 2.5s infinite;
}

.shimmer {
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: skewX(-10deg);
  box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.3);
}

@keyframes loading {
  0% {
    transform: translateX(-60%);
  }

  100% {
    transform: translateX(100%);
  }
}

/* FormContent.js */
.formTitle {
  margin-top: 40px;
}

.formCntRow {
  display: flex;
  margin: 18px 0;
}

.cntTitle {
  width: 20vw;
}

.formCnt {
  width: 50vw;
}

.instruction {
  position: fixed;
  width: calc(100vw - 240px);
  height: calc(100vh - 70px);
  right: 0;
  bottom: 0;
  padding-left: 5vw;
  padding-right: 5vw;
  text-align: left;
  background-color: #ffffff;
  z-index: 9;
}

/*//////home new///////*/
.promoHint {
  margin-bottom: 30px;
  margin-top: -30px;
  width: auto;
  height: auto;
  padding: 14px 18px;
  background-color: rgba(107, 146, 247, 0.1);
  align-items: center;
  text-align: center;
}

.infoBox {
  width: 18vw;
  margin: 10px 90px 100px 10px;
}

.promotionLable {
  color: #db5461;
  padding: 0 5px;
  width: fit-content;
  border: solid 1px #db5461;
  font-weight: 600;
}

.currentPrice {
  color: #db5461;
  font-size: 24px;
  font-weight: 600;
}

.originalPrice {
  color: #64748b;
  text-decoration: line-through #64748b;
}

.peanutBtn {
  position: relative;
  float: right;
  bottom: 12px;
}

.peanutCtn {
  position: relative;
  float: right;
  left: 70px;
  font-weight: 600;
  font-size: 14px;
}
.peanutCtn:hover {
  text-decoration: underline 2px;
  cursor: pointer;
}
/*/ /////home new///////*/

/*infoPage*/

.infoP2 {
  text-align: left;
}

.infoP {
  text-align: left;
  margin: -60px 0 0 0;
  scrollbar-width: thin;
}

.warning {
  display: none;
}

.infoP > div,
.infoP > div > div {
  margin: 20px;
}

.homeAsistBtn {
  position: fixed;
  right: -15px;
  top: 200px;
  background-color: #6b92f7;
  border-radius: 16px 0px 0px 16px;
  width: 55px;
  min-width: 55px;
  height: 230px;
  padding: 16px 32px 16px 16px;
  transition: transform 0.3s;
  transform: translateX(0px);
  z-index: 8;
}

.homeAsistBtn:hover {
  font-size: 17px;
  transition: transform 0.3s;
  transform: translateX(-15px);
}

.homeBlock {
  display: flex;
  align-items: center;
  height: calc(90vh - 70px);
}

.half {
  width: 47.2%;
  padding: 0 0 0 5%;
}

.homeImg {
  width: 100%;
  height: auto;
}

.homeBlock h2 {
  padding-bottom: 350px;
}

.homeBlock ul {
  max-width: 550px;
}

.homeBlock li .bean {
  padding: 0px 10px;
}

.homeBlock li p {
  margin: 10px 0 25px 39px;
}

.details {
  padding: 20px 5%;
}

.infoDivs {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 45px;
}

.infoDiv {
  position: relative;
  background-color: #f1f5f9;
  width: 35%;
  height: auto;
  min-height: 360px;
  padding: 45px 37px 95px 37px;
  margin: 10px 0px;
}

.purple {
  background-color: #6b92f7;
  padding: 50px 0;
}

.purple h1,
footer {
  padding: 0 0 0 5%;
}

.process {
  max-width: 100%;
  height: auto;
}

.process_mid {
  display: none;
}

.flex {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.dynamic {
  display: flex;
  align-items: baseline;
}

.detailInfo {
  width: 43vw;
}

.infoTopic {
  position: relative;
  top: 55px;
  left: -120px;
}

.sticky {
  background-color: #f1f5f9;
  width: 35%;
  min-width: 325px;
  height: auto;
  padding: 40px 32px 90px 32px;
  position: -webkit-sticky;
  position: sticky;
  top: 78px;
}

.afterStickyRcm {
  background-color: #f1f5f9;
  width: 35%;
  min-width: 325px;
  height: auto;
  padding: 40px 32px 90px 32px;
  position: relative;
  top: 750px;
}

.afterStickySop {
  background-color: #f1f5f9;
  width: 35%;
  min-width: 325px;
  height: auto;
  padding: 40px 32px 90px 32px;
  position: relative;
  top: 965px;
}

.afterStickyPrp {
  background-color: #f1f5f9;
  width: 35%;
  min-width: 325px;
  height: auto;
  padding: 40px 32px 90px 32px;
  position: relative;
  top: 255px;
}

.threeInfo {
  padding: 80px 80px 0 80px;
}

.threeInfo .infoDiv {
  width: 50%;
}

.threeInfo .footer {
  position: relative;
  left: -40px;
  bottom: -80px;
  margin: 0;
  width: 100vw;
}

.threeInfo .intro {
  width: 64%;
}

.avatar {
  height: 80px;
  width: 80px;
}

.tag {
  background-color: #f4f2ff;
  margin-right: 12px;
  padding: 5px 8px;
}

.contact-code {
  height: 100px;
  width: 100px;
}

/*RWD*/

@media screen and (max-height: 580px) {
  .extra {
    position: absolute;
    left: -9999px;
  }
  .pay-plus-btn {
    position: fixed;
    bottom: 8vh;
    right: 4vw;
    z-index: 8;
  }
}

@media only screen and (min-width: 1512px) {
}

@media only screen and (max-width: 1024px) {
  /* .threeInfo {
    padding: 80px 20px;
  } */

  .infoP {
    padding: 80px 20px;
  }

  .threeInfo > img {
    width: 100vw;
    margin-left: -20px;
  }

  .threeInfo .footer {
    position: relative;
    left: -10px;
    width: 100vw;
  }

  .threeInfo .intro {
    width: 80%;
  }

  .sidebar,
  .disapear {
    display: none;
  }

  .account,
  .asistant,
  .addFile,
  .myWritings,
  .TabsResult,
  .result,
  .TabSect,
  .formStyle {
    position: static;
    width: 100vw;
  }

  .asistant input {
    width: 25.5vw;
    min-width: 25.5vw;
  }

  .asistant textarea {
    width: 55vw;
  }

  .resultRight {
    width: 30vw;
  }

  .editArea {
    width: 60vw;
  }

  .screenResult {
    transform: translateX(-595px);
  }

  .completion {
    position: fixed;
  }

  .resultRow {
    display: block;
  }

  .tips {
    margin-left: 10px;
  }

  .extra {
    width: 30vw;
  }

  .half {
    padding: 0 0 0 1%;
  }

  .details {
    padding: 100px 1%;
  }

  .purple h1 {
    padding: 0 0 0 1%;
  }

  .flex {
    display: block;
  }
}

@media only screen and (max-width: 799px) {
  .threeInfo .intro {
    width: 100%;
  }

  .navLinks {
    position: absolute;
    left: -9999px;
  }

  .infoP {
    top: 40px;
  }

  .warning {
    position: fixed;
    top: 70px;
    width: 100%;
    padding: 0 1%;
    background-color: #6b92f7;
    color: white;
    display: inline-flex;
    align-items: center;
    z-index: 8;
  }

  .homeBlock {
    display: block;
    height: auto;
  }

  .half {
    width: 100%;
    padding: 30px 1% 50px 1%;
  }

  .imgDiv {
    display: flex;
    justify-content: center;
  }

  .homeBlock h2 {
    padding: 0px 1%;
  }

  .infoP hr {
    margin: 80px 0;
  }

  .details {
    padding: 0px 1% 50px 1%;
  }

  .infoDivs {
    display: block;
  }

  .infoDiv {
    width: 100%;
    height: auto;
    margin: 24px 0;
  }

  .homeBlock ul {
    max-width: 100%;
  }

  .process {
    display: none;
  }

  .process_mid {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .dynamic {
    display: block;
  }

  .detailInfo {
    width: 76%;
  }

  .sticky,
  .afterStickyRcm,
  .afterStickySop,
  .afterStickyPrp {
    position: relative;
    width: 100vw;
    top: -8px;
    left: -15px;
  }

  .threeInfo .infoDiv {
    width: 100%;
  }
}

@media only screen and (min-width: 1511px) {
}
